import React from 'react';
import type { ReactElement } from 'react';
import Section from '@clearscore/shared.website-section';
import Template from '@clearscore/shared.website-template';
import type { IGatsbyImageData } from 'gatsby-plugin-image';
import type { ISection } from '@clearscore/shared.website-section';
import ContentfulComponent from '@clearscore/shared.website-contentful-component';
import SectionTextAndImage from '@clearscore/shared.website-text-and-image';
import SectionFaQs from '@clearscore/shared.website-faq';
import SectionCta from '@clearscore/shared.website-cta-section';
import SectionThreeStep from '@clearscore/shared.three-step';
import SectionCardGrid from '@clearscore/shared.website-card-grid';
import SectionLogoGrid from '@clearscore/shared.logo-grid';
import SectionCtaCard from '@clearscore/shared.website-card-website-cta';
import SectionTwoPanelHero from '@clearscore/shared.website-two-panel-hero';
import SectionRichText from '@clearscore/shared.website-rich-text';
import SectionCardCarousel from '@clearscore/shared.website-mobile-carousel';
import SectionFullscreenHero from '@clearscore/shared.website-fullscreen-hero';
import SectionTestimonials from '@clearscore/shared.website-testimonials';
import SectionDonutLandingHero from '@clearscore/shared.website-donut-landing-hero';
import SectionTextAndImageGrid from '@clearscore/shared.website-text-and-image-grid';
import SectionCreateAccountProspectForm from '@clearscore/shared.website-create-account-prospect-form';
import AnalyticsProvider from '@clearscore/shared.website-analytics-provider';
import SectionLoanCalculator from '@clearscore/shared.website-loan-calculator';
import type { IAppStoreConfig } from '@clearscore/shared.website-app-logos';

import styles from './contentful-page.module.css';

interface ContentfulPageQuery {
    contentfulPage: {
        seoMetadata: {
            title: string;
        };
        title: string;
        // eslint-disable-next-line camelcase
        contentful_id: string;
        content: ReadonlyArray<{
            __typename: string;
            // eslint-disable-next-line camelcase
            contentful_id: string;
            section?: Omit<ISection, 'children' | 'backgroundImage'> & {
                bgImage?: {
                    image: {
                        gatsbyImageData: IGatsbyImageData;
                        description?: string;
                    };
                    imageDesktop: {
                        gatsbyImageData: IGatsbyImageData;
                        description?: string;
                    };
                    imageDarknessPercentage?: number;
                };
            };
            sys: {
                revision: number;
            };
            [x: string]: unknown;
        }>;
        sys: {
            revision: number;
        };
    };
}

export interface ContentfulPageProps {
    appStoreConfig: IAppStoreConfig;
    cookiePolicyConfig: {
        moreInfoLinkUrl: string;
        enabled: boolean;
        language: Record<string, string>;
    };
    data: ContentfulPageQuery;
}

const componentMap: Record<string, React.ElementType> = {
    SectionTextAndImage,
    SectionTwoPanelHero,
    SectionFaQs,
    SectionCta,
    SectionThreeStep,
    SectionCardGrid,
    SectionCtaCard,
    SectionLogoGrid,
    SectionRichText,
    SectionCardCarousel,
    SectionFullscreenHero,
    SectionLoanCalculator,
    SectionTestimonials,
    SectionCreateAccountProspectForm,
    SectionDonutLandingHero,
    SectionTextAndImageGrid,
};

const componentCount: Record<string, number> = {};

function hasTermsNotificationPanel(): boolean {
    const inMarket = ['au', 'nz'].includes(process.env.MARKET || '');
    const isHomePage = ['/', '/au', '/nz'].includes(window?.location.pathname);
    return inMarket && isHomePage;
}

const ContentfulPage = ({ appStoreConfig, cookiePolicyConfig, data }: ContentfulPageProps): ReactElement => {
    const { contentfulPage } = data;
    const hasTermsNotification = hasTermsNotificationPanel();
    return (
        <Template
            appStoreConfig={appStoreConfig}
            cookiePolicyConfig={cookiePolicyConfig}
            pullUpContent
            hasTermsNotification={hasTermsNotification}
        >
            <AnalyticsProvider
                trackingData={{
                    page_id: contentfulPage.contentful_id,
                    page_title: contentfulPage.seoMetadata?.title,
                    page_version: contentfulPage.sys.revision,
                }}
            >
                {contentfulPage.content.map((content, i) => {
                    const count = componentCount[content.__typename] + 1 || 1;
                    // eslint-disable-next-line react-compiler/react-compiler
                    componentCount[content.__typename] = count;
                    return (
                        <div className={styles.fullWidth} key={i}>
                            {'section' in content ? (
                                <Section
                                    {...content.section}
                                    key={`${content.__typename}-${i}`}
                                    backgroundImage={
                                        content.section?.bgImage?.image ? (
                                            <Section.BackgroundImage
                                                image={content.section.bgImage.image}
                                                imageDesktop={content.section.bgImage.imageDesktop}
                                            />
                                        ) : undefined
                                    }
                                    imageDarknessPercentage={content.section?.bgImage?.imageDarknessPercentage}
                                >
                                    <AnalyticsProvider
                                        trackingData={{
                                            section_name: `${content.__typename}-${count}`,
                                            section_id: content.contentful_id,
                                            section_version: content.sys.revision,
                                        }}
                                    >
                                        <ContentfulComponent
                                            componentMap={componentMap}
                                            sectionIndex={i}
                                            {...content}
                                        />
                                    </AnalyticsProvider>
                                </Section>
                            ) : (
                                <AnalyticsProvider
                                    trackingData={{
                                        section_name: `${content.__typename}-${count}`,
                                        section_id: content.contentful_id,
                                        section_version: content.sys.revision,
                                    }}
                                >
                                    <ContentfulComponent
                                        key={`${content.__typename}-${i}`}
                                        componentMap={componentMap}
                                        sectionIndex={i}
                                        {...content}
                                    />
                                </AnalyticsProvider>
                            )}
                        </div>
                    );
                })}
            </AnalyticsProvider>
        </Template>
    );
};

export default ContentfulPage;
