import { useState, useEffect } from 'react';

interface ProgressTimingProps {
    newScore: number;
    oldScore: number;
    maxValue: number;
    isResetting: boolean;
    setIsResetting?: (arg?: boolean) => void;
    progressDuration: number;
}

interface Progress {
    progress: {
        ringOne: number;
        ringTwo: number;
    };
}

const useProgressTimings = ({
    newScore,
    oldScore,
    maxValue,
    isResetting,
    setIsResetting,
    progressDuration,
}: ProgressTimingProps): Progress => {
    const [progress, setProgress] = useState({ ringOne: 0, ringTwo: 0 });

    const midTiming = progressDuration * 2;
    const endTiming = progressDuration * 2.75;

    useEffect(() => {
        let startTimeout: ReturnType<typeof setTimeout>;
        let midTimeout: ReturnType<typeof setTimeout>;
        let endTimeout: ReturnType<typeof setTimeout>;
        let resetTimeout: ReturnType<typeof setTimeout>;

        if (oldScore) {
            // Sets both progress rings to the old score
            startTimeout = setTimeout(() => {
                setProgress({ ringOne: Math.abs(oldScore / maxValue), ringTwo: Math.abs(oldScore / maxValue) });
            }, progressDuration);
        }

        if (oldScore <= newScore) {
            // Adjusts second progress ring to new score
            midTimeout = setTimeout(() => {
                setProgress((state) => ({
                    ...state,
                    ringTwo: Math.abs(newScore / maxValue),
                }));
            }, midTiming);

            // Adjusts first progress ring to new score
            endTimeout = setTimeout(() => {
                setProgress((state) => ({
                    ...state,
                    ringOne: Math.abs(newScore / maxValue),
                }));
            }, endTiming);
        }

        if (newScore < oldScore) {
            // Adjusts first progress ring to new score
            midTimeout = setTimeout(() => {
                setProgress((state) => ({
                    ...state,
                    ringOne: Math.abs(newScore / maxValue),
                }));
            }, midTiming);

            // Adjusts second progress ring to new score
            endTimeout = setTimeout(() => {
                setProgress((state) => ({
                    ...state,
                    ringTwo: Math.abs(newScore / maxValue),
                }));
            }, endTiming);
        }

        if (isResetting || !newScore) {
            // Resets rings to 0 if user interacts with donut
            setProgress({ ringOne: 0, ringTwo: 0 });
            resetTimeout = setTimeout(() => {
                if (setIsResetting) setIsResetting();
            }, progressDuration);
        }

        return (): void => {
            clearTimeout(startTimeout);
            clearTimeout(midTimeout);
            clearTimeout(endTimeout);
            clearTimeout(resetTimeout);
        };
        // todo: remove eslint-disable and follow advice
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newScore, isResetting]);
    return { progress };
};

export default useProgressTimings;
