import React, { Fragment } from 'react';
import Text from '@clearscore/ui.rainbow.text';
import ContentCarousel from '@clearscore/ui.rainbow.content-carousel';
import CardTemplateA from '@clearscore/ui.rainbow.card-template-a';
import { useTrackingData } from '@clearscore/shared.website-analytics-provider';
import Tag from '@clearscore/ui.rainbow.tag';
import type { IContentfulRichTextRendererProps } from '@clearscore/shared.website-contentful-rich-text-renderer';
import RichText from '@clearscore/shared.website-contentful-rich-text-renderer';
import { H3 } from '@clearscore/ui.cms.typography-logged-in';

import { useClickTracking } from './lib/hooks/use-click-tracking';
import styles from './mobile-carousel.module.css';

export interface IMobileCarouselProps {
    heading: string;
    cards: Array<{ tag: string; title: string; description: string; href: string }>;
}

export interface IContentfulMobileCarouselProps {
    // eslint-disable-next-line camelcase
    contentful_id: string;
    heading: string;
    cards: Array<{
        tag: string;
        title: string;
        description: IContentfulRichTextRendererProps['text'];
        href: string;
        // eslint-disable-next-line camelcase
        contentful_id: string;
        sys: {
            revision: number;
        };
    }>;
}

// type guard to check if the cards are coming from contentful
const isContentfulCards = (
    cards: IMobileCarouselProps['cards'] | IContentfulMobileCarouselProps['cards'],
): cards is IContentfulMobileCarouselProps['cards'] =>
    (cards as IContentfulMobileCarouselProps['cards'])?.[0]?.contentful_id !== undefined;

export const MobileCarousel = ({
    heading,
    cards = [],
}: IMobileCarouselProps | IContentfulMobileCarouselProps): React.ReactElement => {
    const { data: _data } = useTrackingData();

    const trackCtaClicked = useClickTracking();

    return (
        <Fragment>
            <Text tag={Text.tags.H2} weight={Text.weights.BOLD} type={Text.types.LARGE}>
                {heading}
            </Text>
            <ContentCarousel
                maxCarouselItems={{
                    ALL: 1,
                    PHABLET: 2,
                    MEDIUM: 3,
                }}
                maxInlineItems={{
                    ALL: 1,
                    PHABLET: 2,
                    MEDIUM: 3,
                }}
            >
                {isContentfulCards(cards)
                    ? // eslint-disable-next-line camelcase
                      cards.map(({ tag, title, description, href, contentful_id, sys: { revision } }, key) => {
                          const data = {
                              ..._data,
                              href,
                              // eslint-disable-next-line camelcase
                              entry_id: contentful_id,
                              entry_version: revision,
                          };

                          return (
                              <div className={styles.mobileScroll} key={`carousel-card-${key}`}>
                                  <CardTemplateA
                                      tag={<Tag displayState={Tag.displayState.TRANSPARENT}>{tag}</Tag>}
                                      displayState="positive"
                                      title={<H3>{title}</H3>}
                                      description={<RichText text={description} />}
                                      key={key}
                                      href={href}
                                      onClick={() => {
                                          trackCtaClicked(data);
                                      }}
                                  />
                              </div>
                          );
                      })
                    : cards.map(({ tag, title, description, href }, key) => (
                          <div className={styles.mobileScroll} key={`carousel-card-${key}`}>
                              <CardTemplateA
                                  tag={<Tag displayState={Tag.displayState.TRANSPARENT}>{tag}</Tag>}
                                  displayState="positive"
                                  title={<Text.H3>{title}</Text.H3>}
                                  description={<Text.Body2>{description}</Text.Body2>}
                                  key={key}
                                  href={href}
                              />
                          </div>
                      ))}
            </ContentCarousel>
        </Fragment>
    );
};

export default MobileCarousel;
