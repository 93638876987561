import type { FC } from 'react';
import React from 'react';
import cx from 'classnames';
import Card from '@clearscore/ui.rainbow.card';

import type { ICardTemplateAProps } from './types';
import styles from './card-template-a.module.css';

const CardTemplateA: FC<ICardTemplateAProps> = ({
    tag,
    title,
    subtitle,
    description,
    displayState,
    cornerImageSrc,
    onClick,
    href,
    ariaLabel,
}) => (
    <Card borderRadius={Card.BorderRadius.Large} href={href} onClick={onClick} ariaLabel={ariaLabel}>
        <Card.ContentContainer sectionPosition={Card.SectionPosition.VERTICAL}>
            <Card.ContentPrimary displayState={displayState} cornerImageSrc={cornerImageSrc}>
                <div className={styles['content-container']}>
                    {!!tag && tag}
                    <div
                        className={cx(styles['title-container'], {
                            [styles['title-container--with-subtitle']]: subtitle,
                            [styles['title-container--without-tag']]: !tag,
                        })}
                    >
                        {title}
                        {!!subtitle && <div className={styles['subtitle-container']}>{subtitle}</div>}
                    </div>
                </div>
            </Card.ContentPrimary>
            <Card.ContentSecondary>
                <div className={styles['description-container']}>{description}</div>
            </Card.ContentSecondary>
        </Card.ContentContainer>
    </Card>
);

export default CardTemplateA;
